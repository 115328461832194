import React from 'react';
import { FaSearch, FaStar, FaShoppingCart, FaChartLine } from 'react-icons/fa';

const HomePage = () => {
  return (
    <div className="home-page">
      <section className="hero">
        <h1>Trouvez les meilleurs produits sur Internet</h1>
        <p>Comparez, évaluez et achetez en toute confiance sur meilleur-qualite-prix.com</p>
      </section>

      <section className="features">
        <div className="feature">
          <FaSearch className="feature-icon" />
          <h3>Recherche intelligente</h3>
          <p>Trouvez rapidement les produits qui vous intéressent</p>
        </div>
        <div className="feature">
          <FaStar className="feature-icon" />
          <h3>Avis vérifiés</h3>
          <p>Des évaluations fiables pour guider vos choix</p>
        </div>
        <div className="feature">
          <FaShoppingCart className="feature-icon" />
          <h3>Comparaison facile</h3>
          <p>Comparez les prix et les caractéristiques en un clin d'œil</p>
        </div>
        <div className="feature">
          <FaChartLine className="feature-icon" />
          <h3>Meilleures offres</h3>
          <p>Découvrez les 10 meilleures offres pour chaque recherche</p>
        </div>
      </section>

      <section className="popular-categories">
        <h2>Catégories populaires</h2>
        <div className="category-grid">
          <div className="category">Électronique</div>
          <div className="category">Mode</div>
          <div className="category">Maison et cuisine</div>
          <div className="category">Sports et loisirs</div>
          <div className="category">Beauté et santé</div>
          <div className="category">Livres</div>
        </div>
      </section>

      <section className="about-us">
        <h2>À propos de meilleur-qualite-prix.com</h2>
        <p>Notre site est dédié à vous aider à trouver les meilleures offres en ligne. Notre algorithme de recherche avancé analyse des milliers de produits pour vous présenter les 10 meilleures options basées sur la qualité, le prix et les avis des utilisateurs.</p>
      </section>
    </div>
  );
};

export default HomePage;