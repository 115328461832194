import React from 'react';

const SimilarOffers = ({ offers }) => {
  if (!offers || offers.length === 0) {
    return null;
  }

  return (
    <div className="similar-offers">
      <div className="offers-grid">
        {offers.map((offer, index) => (
          <div key={index} className="offer-card">
            <div className="offer-image-container">
              <img src={offer.image} alt={offer.title} className="offer-image" />
            </div>
            <div className="offer-details">
              <h3 className="offer-title">{offer.title}</h3>
              <div className="offer-price">{offer.price}</div>
              <a href={offer.url} className="offer-button" target="_blank" rel="noopener noreferrer">
                Voir l'offre
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SimilarOffers;