import React, { useState } from 'react';
import axios from 'axios';
import './App.css';
import SimilarOffers from './components/SimilarOffers';
import HomePage from './components/HomePage';

function App() {
  const [query, setQuery] = useState('');
  const [products, setProducts] = useState([]);
  const [similarOffers, setSimilarOffers] = useState([]);
  const [error, setError] = useState('');
  const [expandedItems, setExpandedItems] = useState({});
  const [hasSearched, setHasSearched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async (e) => {
    e.preventDefault();
    setError('');
    setHasSearched(true);
    setIsLoading(true);
    try {
      const response = await axios.get(`/api/search?q=${query}`);
      console.log('Search response:', response.data);
      setProducts(response.data.mainResults || []);
      setSimilarOffers(response.data.similarOffers || []);
    } catch (error) {
      console.error(error);
      setError('Erreur lors de la recherche des produits. Veuillez réessayer.');
    } finally {
      setIsLoading(false);
    }
  };

  const toggleExpand = (id) => {
    setExpandedItems(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  function getRatingText(rating) {
    if (rating >= 9.5) return "Exceptionnel";
    if (rating >= 9.0) return "Excellent";
    if (rating >= 8.5) return "Très bien";
    if (rating >= 8.0) return "Bien";
    return "Moyen";
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="header-content">
          <h1>Le Meilleur Avis</h1>
          <form onSubmit={handleSearch} className="search-form">
            <input 
              type="text" 
              value={query} 
              onChange={(e) => setQuery(e.target.value)} 
              placeholder="Rechercher un produit" 
            />
            <button type="submit" disabled={isLoading}>
              {isLoading ? 'Recherche...' : 'Rechercher'}
            </button>
          </form>
        </div>
      </header>
      
      <main className="main-content">
        {!hasSearched ? (
          <HomePage />
        ) : (
          <>
            {error && <p className="error-message">{error}</p>}
            
            {isLoading ? (
              <p className="loading">Chargement des résultats...</p>
            ) : (
              <div className="product-list">
                {products.map((product, index) => (
                  <div key={product.id} className="product-item">
                    <div className="product-rank">{String(index + 1).padStart(2, '0')}</div>
                    {index === 0 && <div className="best-choice">Meilleur choix</div>}
                    {index === 1 && <div className="best-value">Meilleure Valeur</div>}
                    <div className="product-image-container">
                      <img src={product.image} alt={product.name} className="product-image" />
                    </div>
                    <div className="product-details">
                      <h2 className="product-title">{product.name}</h2>
                      <div className="product-highlights">
                        <h3>Faits saillants principaux</h3>
                        <p>{product.shortDescription}</p>
                      </div>
                      <div 
                        className="product-more" 
                        onClick={() => toggleExpand(product.id)}
                      >
                        {expandedItems[product.id] ? 'Afficher Moins ▲' : 'Afficher Plus ▼'}
                      </div>
                      {expandedItems[product.id] && (
                        <div className="product-description">{product.description}</div>
                      )}
                    </div>
                    <div className="product-rating">
                      <div className="rating-score">{product.rating.toFixed(1)}</div>
                      <div className="rating-text">{getRatingText(product.rating)}</div>
                      <div className="rating-stars">{'★'.repeat(Math.round(product.rating))}</div>
                      <a href={product.url} target="_blank" rel="noopener noreferrer" className="view-offer-btn">
                        Voir l'offre
                      </a>
                      <div className="on-amazon">Sur Amazon</div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {similarOffers.length > 0 && (
              <div className="similar-offers-section">
                <h2>Des offres similaires qui pourraient vous intéresser</h2>
                <SimilarOffers offers={similarOffers} />
              </div>
            )}
          </>
        )}
      </main>
    </div>
  );
}

export default App;